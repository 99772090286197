
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'

export default defineComponent({
  setup () {
    const router = useRouter()

    onMounted(() => {
      data.inter = setInterval(() => {
        data.countdown--
        if (data.countdown === 0) {
          clearInterval(data.inter)
          data.goBack()
        }
      }, 1000)
    })

    const data: any = reactive({
      inter: null,
      countdown: 5,

      goBack: () => {
        router.push('/')
      }
    })

    onBeforeRouteLeave(async (to, from, next) => {
      clearInterval(data.inter)
      next()
    })

    return {
      ...toRefs(data)
    }
  }
})
